
import { defineComponent, ref, reactive, toRefs, inject, nextTick } from 'vue'
import { ElMessage } from 'element-plus'
import { membersApi } from '@/api/modules/members'

export default defineComponent({
  name: 'administratorAddOrEditDialog',
  setup (props, ctx) {
    // 获取refs
    const addOrEditFormRef = ref()

    // 接收父级的方法
    const updateList: any = inject('updateList')

    // 新增、编辑表单数据
    const addOrEditFormInit = () => {
      return {
        username: '',
        mobile: '',
        role_id: '',
        position_id: '',
        avatar: ''
      }
    }

    const data: any = reactive({
      addOrEditDialog: false,
      addOrEditType: 0, // 0新增,1编辑
      addOrEditTypeTitle: ['新增', '编辑'],
      addOrEditForm: addOrEditFormInit(),
      addOrEditRules: {
        username: [{ required: true, message: '请输入名称', trigger: 'blur' }],
        mobile: [
          { required: true, message: '请输入手机号码', trigger: 'blur' },
          { min: 11, max: 11, message: '请输入11位手机号码', trigger: 'blur' },
          {
            pattern: /^(13[0-9]|14[579]|15[0-3,5-9]|16[6]|17[0135678]|18[0-9]|19[89])\d{8}$/,
            message: '请输入正确的手机号码'
          }
        ],
        role_id: [{ required: true, message: '请选择角色名称', trigger: 'change' }],
        position_id: [{ required: true, message: '请选择岗位', trigger: 'change' }],
        avatar: [{ required: true, message: '请上传头像' }]
      },
      determineBtnLoading: false,

      // 对话框显示
      async showAddOrEdit (user_id: number) {
        data.addOrEditType = user_id ? 1 : 0

        // 有id时赋值
        if (user_id) {
          const { res } = await membersApi.getInfo({ user_id })
          // console.log('根据id获取详情', res)
          data.addOrEditForm = res
          data.addOrEditForm.roleSelectedObj = {
            id: res.role_id,
            name: res.role_name
          }
          data.addOrEditForm.positionSelectedObj = {
            id: res.position_id,
            name: res.position_name
          }
        }
        data.addOrEditDialog = true
      },
      // 对话框关闭
      addOrEditCancel () {
        // console.log('对话框关闭')
        addOrEditFormRef.value.resetFields()
        data.addOrEditForm = Object.assign({}, addOrEditFormInit())
      },
      // 对话框确认
      addOrEditDetermine () {
        // console.log('data.addOrEditForm', data.addOrEditForm)
        addOrEditFormRef.value.validate(async (valid: any) => {
          if (!valid) return

          try {
            data.determineBtnLoading = true
            await membersApi.save(data.addOrEditForm)
            ElMessage.success(`${data.addOrEditTypeTitle[data.addOrEditType]}成功`)
            data.addOrEditDialog = false
            data.determineBtnLoading = false

            // 父级更新列表
            updateList('place')
          } catch (error) {
            data.determineBtnLoading = false
          }
        })
      }
    })

    return {
      ...toRefs(data),
      addOrEditFormRef
    }
  }
})
