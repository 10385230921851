
import { defineComponent, ref, reactive, toRefs } from 'vue'
import { ElMessage } from 'element-plus'
import { membersApi } from '@/api/modules/members'

export default defineComponent({
  name: 'administratorEditPasswordDialog',
  setup (props, ctx) {
    // 获取refs
    const formRef = ref()

    const data: any = reactive({
      editPasswordDialog: false,
      form: {
        user_id: null,
        pd: ''
      },
      formRules: {
        pd: [{ required: true, message: '请输入新密码', trigger: 'blur' }]
      },

      // 对话框显示
      async showDialog (id: number) {
        data.form.user_id = id
        data.editPasswordDialog = true
      },
      // 对话框关闭
      editPasswordCancel () {
        // console.log('对话框关闭')
        formRef.value.resetFields()
      },
      // 对话框确认
      editPasswordDetermine () {
        // console.log('data.form', data.form)
        formRef.value.validate(async (valid: any) => {
          if (!valid) return

          await membersApi.changePd(data.form)
          ElMessage.success('密码修改成功')
          data.editPasswordDialog = false
        })
      }
    })

    return {
      ...toRefs(data),
      formRef
    }
  }
})
